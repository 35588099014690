import * as hash from 'hash.js'
import { Buffer } from 'buffer'
import { joinURL } from 'ufo'

function createMapper(map) {
  return (key) => {
    return key ? map[key] || key : map.missingValue
  }
}
function createOperationsGenerator({ formatter, keyMap, joinWith = '/', valueMap } = {}) {
  if (!formatter) {
    formatter = (key, value) => `${key}=${value}`
  }
  if (keyMap && typeof keyMap !== 'function') {
    keyMap = createMapper(keyMap)
  }
  const map = valueMap || {}
  Object.keys(map).forEach((valueKey) => {
    if (typeof map[valueKey] !== 'function') {
      map[valueKey] = createMapper(map[valueKey])
    }
  })
  return (modifiers = {}) => {
    const operations = Object.entries(modifiers)
      .filter(([_, value]) => typeof value !== 'undefined')
      .map(([key, value]) => {
        const mapper = map[key]
        if (typeof mapper === 'function') {
          value = mapper(modifiers[key])
        }
        key = typeof keyMap === 'function' ? keyMap(key) : key
        return formatter(key, value)
      })
    return operations.join(joinWith)
  }
}

const operationsGenerator = createOperationsGenerator({
  keyMap: {
    resize: 'rs',
    size: 's',
    fit: 'rt',
    width: 'w',
    height: 'h',
    dpr: 'dpr',
    enlarge: 'el',
    extend: 'ex',
    gravity: 'g',
    crop: 'c',
    padding: 'pd',
    trim: 't',
    rotate: 'rot',
    quality: 'q',
    maxBytes: 'mb',
    background: 'bg',
    backgroundAlpha: 'bga',
    blur: 'bl',
    sharpen: 'sh',
    watermark: 'wm',
    preset: 'pr',
    cacheBuster: 'cb',
    stripMetadata: 'sm',
    stripColorProfile: 'scp',
    autoRotate: 'ar',
    filename: 'fn',
    format: 'f',
  },
  formatter: (key, value) => `${key}:${value}`,
})

const urlSafeBase64 = (string) => {
  return Buffer.from(string).toString('base64').replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}

const hexDecode = (hex) => Buffer.from(hex, 'hex')

const sign = (salt, target, secret) => {
  const hmac = hash.hmac(hash.sha256, hexDecode(secret))
  hmac.update(hexDecode(salt))
  hmac.update(target)
  return urlSafeBase64(hmac.digest())
}

const defaultModifier = {
  fit: 'fill',
  width: 291,
  height: 400,
  gravity: 'no',
  enlarge: 1,
  format: 'png',
}

export default (src, { modifiers } = {}) => {
  const config = useRuntimeConfig()

  const mergeModifiers = {
    ...defaultModifier,
    ...modifiers,
  }

  if (!src) return '/images/loading.png'

  const url = `${config.public.imagePath}/${src}.png`
  const type = 'png'
  const prefix = joinURL('/', operationsGenerator(mergeModifiers))

  const encrypted = `${prefix}/${urlSafeBase64(url)}.${type}`
  const signature = sign(config.public.salt, encrypted, config.public.key)

  return joinURL(config.public.domain, `${signature}${encrypted}`)
}
